@font-face {
  font-family: Sligoil;
  src:    url('./assets/fonts/Sligoil/Sligoil-Micro.woff') format('woff'),
          url('./assets/fonts/Sligoil/Sligoil-Micro.woff2') format('woff2'),
          url('./assets/fonts/Sligoil/SligoilMM-Regular.otf') format('opentype');
}

.App {
  font-size: 16px;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.App div, button {
  font-family: "Sligoil", monospace;
  white-space: pre;
  line-height: 1rem;
}

.App div p {margin: 0}

button {
  padding: 0;
  border: 0;
  font-size: 1rem;
  margin: 0;
  cursor: pointer;
}

main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.phone-background {
  background-color: white;
  border-right: solid 1px white;
  box-shadow: 16px 0 black;
}

#phone {
  position: absolute;
}

#phone-aerial, #phone-top {
  border-top: solid 1px white;
  border-radius: 30px 30px 0 0;
}

#phone-bottom {
  border-bottom: solid 1px white;
  border-radius: 0 0 30px 30px;
  box-shadow: 16px 4px black,16px 0 black;
}

pre {
  margin: 0;
}
